<template>
  <div class="div-block-7 article-content-subpage">
    <div class="div-block-14">
      <h1>Mein Profil</h1>
    </div>
    <div class="block-article--intro">
      <div class="block-article-content">
        <div class="w-richtext">
          <p>
            In deinem Profil kannst du deine Kontaktdaten bearbeiten,
            individuellen Benachrichtigungen pro Kurs festlegen und dein
            Newsletter-Abonnement verwalten.
          </p>
        </div>
      </div>
    </div>
    <div class="block-headline block-headline-news">Abonnierte Neuigkeiten</div>
    <div class="block-article block-article--narrow" v-if="user">
      <div class="block-article-content">
        <SubscriptionList :user="user" />
      </div>
    </div>
    <!--
        <div class="block-headline block-headline-news">
          Newsletter
        </div>
        <div class="block-article block-article--narrow">
          <div class="block-article-content">
            <p class="text-meta">
              Hier kannst du demnächst unseren Newsletter abonnieren.
            </p>
          </div>
        </div>
        -->
    <div class="block-headline block-headline-news">Deine Profildaten</div>

    <div
      v-if="loading"
      style="
        text-align: center;
        box-shadow: none;
        color: #747474;
        font-size: 14px;
        background-color: transparent;
      "
    >
      <p>Lade Benutzer …</p>
    </div>
    <div v-else class="block-article block-article--narrow">
      <p>
        Du bist mit der E-Mail-Adresse
        <strong>{{ profile.email }}</strong> registriert.
      </p>
      <!---
      <div class="block-article block-article--narrow" v-if="user">
        <div class="block-article-content">
          <form class="form" v-on:submit.prevent="savePhoneNumber">
            <strong class="text-meta">Handynummer</strong><br />
            <input
              class="w-input"
              placeholder="Keine Handynummer hinterlegt."
              type="tel"
              name="tel"
              id="phoneNumber"
              v-model.trim="profile.phoneNumber"
              autocomplete="tel"
              spellcheck="false"
              autocorrect="off"
              autocapitalize="none"
              minlength="5"
              pattern="^\+[1-9]{1}[0-9]{3,14}$"
              @input="error = null"
            />
            <span class="text-meta"
              >Internationales Format erforderlich, z.B. +4916012345670.</span
            >
            <button
              v-if="phoneNumberDidChange"
              class="button bordered blue default"
              :class="{ green: phoneNumberChangedSuccess }"
              style="max-width: 400px"
            >
              {{
                phoneNumberChangedSuccess
                  ? "Handynummer gespeichert"
                  : "Neue Handynummer speichern"
              }}
            </button>
          </form>
        </div>
      </div>
      -->
    </div>

    <div class="w-row">
      <div class="column-2 w-col w-col-6" style="float: left">
        <button
          class="button fluid blue bordered large"
          :class="{ disabled: loading }"
          style="background-color: transparent; margin-bottom: 2em"
          :disabled="loading"
          @click="logout"
        >
          Abmelden
        </button>
      </div>
      <div class="column w-col w-col-6" style="float: right">
        <router-link
          :to="{ name: 'delete-profile' }"
          class="button fluid red large"
          :class="{ disabled: loading }"
          style="background-color: transparent"
          :disabled="loading"
        >
          Profil löschen
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { firebase } from "@firebase/app";
import "@firebase/auth";
import { mapGetters } from "vuex";
import SubscriptionList from "@/views/SubscriptionList.vue";

export default {
  name: "profile",
  metaInfo: {
    title: "Mein Profil",
  },
  data() {
    return {
      loading: false,
      profile: {
        email: null,
        phoneNumber: null,
      },
      phoneNumberChangedSuccess: false,
    };
  },
  components: {
    SubscriptionList,
  },
  watch: {
    user: {
      deep: true,
      handler() {
        this.profile.email =
          this.user && this.user.email ? this.user.email : null;
        this.profile.phoneNumber =
          this.user && this.user.phoneNumber ? this.user.phoneNumber : null;
      },
    },
  },
  computed: {
    ...mapGetters({
      loading: "user/loading",
      user: "user/current",
    }),
    authUser() {
      return firebase.auth().currentUser;
    },
    emailDidChange() {
      if (!this.user) return null;
      return this.profile.email != this.user.email;
    },
    phoneNumberDidChange() {
      if (!this.user) return null;
      return this.profile.phoneNumber != this.user.phoneNumber;
    },
  },
  mounted() {
    this.setTempData();
  },
  methods: {
    setTempData() {
      if (!this.user) return;
      this.profile.email = this.user.email;
      this.profile.phoneNumber = this.user.phoneNumber;
    },
    saveEmail() {},
    savePhoneNumber() {
      this.$store
        .dispatch("user/updateProfile", {
          user: firebase.auth().currentUser,
          property: "phoneNumber",
          value: this.profile.phoneNumber,
        })
        .then(() => {
          this.phoneNumberChangedSuccess = true;
        });
    },
    logout() {
      this.loading = true;
      const self = this;
      this.$store
        .dispatch("user/logout")
        .then(() => self.$router.replace({ name: "home" }));
    },
  },
};
</script>

<style scoped>
input {
  margin-top: 5px;
  max-width: 400px;
}

.block-article--narrow {
  margin-top: 5px !important;
}

@media (min-width: 768px) {
  .w-row,
  .w-col {
    padding: 0 !important;
  }
  .column > .button {
    float: right;
    text-align: right;
  }
  .column-2 > .button {
    float: left;
    margin-left: 0;
  }
}
</style>
