<template>
  <div>
    <div
      v-if="loading"
      style="
        text-align: center;
        box-shadow: none;
        color: #747474;
        font-size: 14px;
        background-color: transparent;
      "
    >
      <p>Lade Abonnements …</p>
    </div>
    <div v-else-if="subscriptions && subscriptions.length > 0">
      <div
        v-for="subscription in subscriptions"
        :key="subscription.id"
        class="subscription"
      >
        <router-link
          :to="{ name: 'event', params: { id: subscription.event.id } }"
        >
          <h4 style="margin-bottom: 0">{{ subscription.event.title }}</h4>
        </router-link>
        <div>
          <span class="text-meta "
            ><strong>E-Mail-Benachrichtigung:</strong>
            {{ subscription.email ? "Aktiv" : "Inaktiv" }}
            </span
          ><br />
          <!--
          <span class="text-meta"
            ><strong>SMS-Benachrichtigung:</strong>
            {{
              !user.phoneNumber
                ? "Inaktiv (Handynummer erforderlich)"
                : subscription.sms
                ? "Aktiv"
                : "Inaktiv"
            }}
            <span class="text-meta link" v-if="user.phoneNumber"
              ><a
                href
                @click.prevent="
                  updateSubscription(subscription, 'sms', !subscription.sms)
                "
                >{{ !subscription.sms ? "(Aktivieren)" : "(Deaktivieren)" }}</a
              ></span
            ></span
          >
          -->
        </div>
      </div>
    </div>
    <div v-else>
      <span class="text-meta" style="font-size: 16px"
        >Noch keine abonnierten Neuigkeiten.</span
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SubscriptionList",
  props: ["user"],
  computed: {
    ...mapGetters({
      loading: "subscription/loading",
      subscriptions: "subscription/list",
    }),
  },
  mounted() {
    $(".ui.checkbox");
  },
  methods: {
    updateSubscription(subscription, channel, value) {
      let localValue;
      if (channel === "email") {
        localValue = value ? this.user.email : null;
      } else if (channel === "sms") {
        localValue = value ? this.user.phoneNumber : null;
      }

      this.$store.dispatch("subscription/updateChannel", {
        subscription: subscription,
        channel: channel,
        value: localValue,
      });
    },
  },
};
</script>

<style>
.subscription {
  margin-bottom: 20px;
}

.subscription:last-child {
  margin-bottom: 0;
}
</style>
