<template>
  <div class="section-content" id="content">
    <div class="container-content">
      <div class="div-block-7 article-content-subpage" style="max-width: 500px">
        <div class="div-block-14" style="padding-bottom: 10px">
          <h1 class="heading-link heading-subpage">Profil löschen</h1>
          <p>Schade, dass du dein Profil löschen möchtest.</p>
          <p>
            Mit der Löschung werden alle Teilnahmen und Abonnements direkt
            gelöscht und können später nicht wiederhergestellt werden.
          </p>
        </div>
        <div class="block-article" v-if="!success">
          <div class="block-article-content">
            <form
              :class="{ loading }"
              class="form"
              v-on:submit.prevent="deleteProfile"
            >
              <div>
                <label class="text-meta">Dein aktuelles Passwort</label>
                <input
                  class="w-input"
                  v-model="credentials.password"
                  placeholder="********"
                  type="password"
                  id="password"
                  name="password"
                  autocomplete="current-password"
                  spellcheck="false"
                  autocorrect="off"
                  autocapitalize="none"
                  minlength="8"
                  required
                  @input="error = null"
                />
              </div>
              <div v-if="error" class="w-form-fail">
                <strong>Löschung fehlgeschlagen</strong><br />
                <p>{{ error }}</p>
              </div>
              <button
                :class="{ loading }"
                :disabled="loading"
                class="button red bordered default"
              >
                Profil unwiderruflich löschen
              </button>
            </form>
          </div>
        </div>

        <div
          v-if="!success"
          class="block-article block-article-content"
          style="
            margin-top: 30px;
            background: transparent;
            border: none;
            box-shadow: none;
          "
        >
          <div style="width: 100%; text-align: center">
            <p style="text-align: center">
              Du hast es dir (zum Glück) nochmal anders überlegt?
            </p>
            <router-link
              tag="button"
              :to="{ name: 'profile' }"
              class="button fluid blue bordered default"
              >Zurück zum Profil</router-link
            >
          </div>
        </div>

        <div
          class="block-article block-article-content"
          v-else
          style="padding-top: 0"
        >
          <h3>Profil wurde gelöscht</h3>
          <p>
            Die Roboter waren fleißig und haben dein Profil restlos entfernt.
            Vielleicht sehen wir uns ja bald wieder!
          </p>
          <router-link :to="{ name: 'home' }"
            >&larr; Zurück zur Startseite</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firebase } from "@firebase/app";
import "@firebase/auth";

export default {
  name: "DeleteProfile",
  metaInfo: {
    title: "Profil löschen",
  },
  data() {
    return {
      loading: false,
      error: null,
      success: null,
      credentials: {
        password: null,
      },
    };
  },
  computed: {
    user() {
      return firebase.auth().currentUser;
    },
  },
  methods: {
    deleteProfile() {
      this.loading = true;
      const self = this;

      this.$store
        .dispatch("user/delete", this.credentials)
        .then(() => self.$router.push({ name: "home" }))
        .catch(err => {
          this.error = err.message;
          this.loading = false;
        });
    },
  },
};
</script>
