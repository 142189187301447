<template>
  <div class="div-block-7 article-content-subpage" style="max-width: 500px">
    <div class="div-block-14" style="padding-bottom: 10px">
      <h1 class="heading-link heading-subpage">Passwort zurücksetzen</h1>
      <p>
        Bitte trage deine zuletzt genutzte E-Mail-Adresse ein, um weitere
        Anweisungen zum Zurücksetzen deines Passworts per E-Mail zu erhalten.
      </p>
    </div>
    <div class="block-article" v-if="!sent">
      <div class="block-article-content">
        <form :class="{ loading }" class="form" v-on:submit.prevent="sendReset">
          <div class="required">
            <label class="text-meta">Deine E-Mail-Adresse</label>
            <input
              class="w-input"
              v-model="credentials.email"
              placeholder="max.muster@email.de"
              type="email"
              name="email"
              autocomplete="email"
              spellcheck="false"
              autocorrect="off"
              autocapitalize="none"
              minlength="5"
              required
              @input="error = null"
            />
          </div>
          <div v-if="error" class="w-form-fail">
            <strong>Zurücksetzen fehlgeschlagen</strong><br />
            <span>{{ error }}</span>
          </div>
          <button
            :class="{ loading }"
            :disabled="loading"
            class="w-button"
            style="
              width: 100%;
              margin-top: 10px;
              font-weight: 600;
              height: 50px;
            "
          >
            Passwort zurücksetzen
          </button>
        </form>
      </div>
    </div>
    <div
      v-if="!sent"
      style="
        width: 100%;
        text-align: center;
        font-size: 14px;
        height: 44px;
        line-height: 44px;
        margin-top: -10px;
      "
    >
      <router-link :to="{ name: 'login' }"
        >&larr; Zurück zur Anmeldung</router-link
      >
    </div>
    <div class="block-article block-article-content" v-if="sent">
      <h3>Du hast Post!</h3>
      <p>
        Du hast soeben eine E-Mail mit weiteren Anweisungen erhalten. Prüfe
        daher deinen Posteingang (und ggf. Spam-Ordner), ob eine neue E-Mail
        angekommen ist.
      </p>
      <router-link :to="{ name: 'login' }"
        >&larr; Zurück zur Anmeldung</router-link
      >
    </div>
  </div>
</template>

<script>
import { firebase } from "@firebase/app";
import "@firebase/auth";

export default {
  name: "RecoverUserPassword",
  metaInfo: {
    title: "Passwort zurücksetzen",
  },
  data() {
    return {
      loading: false,
      error: null,
      sent: false,
      credentials: {
        email: null,
      },
    };
  },
  computed: {
    user() {
      return firebase.auth().currentUser;
    },
  },
  mounted() {
    if (this.user) {
      this.$router.replace({ name: "profile" });
    }
  },
  methods: {
    sendReset() {
      this.loading = true;

      this.$store
        .dispatch("user/sendRecoverPasswordEmail", this.credentials)
        .then(() => {
          this.sent = true;
        })
        .catch(err => {
          if (err.code === "auth/user-not-found") {
            this.error =
              "Für diese E-Mail-Adresse ist noch kein Profil registriert oder es wurde zwischenzeitlich gelöscht.";
          } else {
            this.error = err.message;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
