<template>
  <div class="div-block-7 article-content-subpage" style="max-width: 500px">
    <div class="div-block-14" style="padding-bottom: 10px">
      <h1 class="heading-link heading-subpage">Mein Profil</h1>
      <p>
        Bitte melde dich erneut in deinem Profil an, um deine Teilnahme an
        Veranstaltungen zu bestätigen oder dich über wichtige Neuigkeiten zu
        Veranstaltungen informieren zu lassen.
      </p>
    </div>
    <div class="block-article">
      <div class="block-article-content">
        <form :class="{ loading }" class="form" v-on:submit.prevent="login">
          <div class="required">
            <label class="text-meta">Deine E-Mail-Adresse</label>
            <input
              class="w-input"
              v-model="credentials.email"
              placeholder="max.muster@email.de"
              type="email"
              name="email"
              autocomplete="email"
              spellcheck="false"
              autocorrect="off"
              autocapitalize="none"
              minlength="5"
              required
              @input="error = null"
            />
          </div>
          <div>
            <label class="text-meta">Dein Passwort</label>
            <input
              class="w-input"
              v-model="credentials.password"
              placeholder="********"
              type="password"
              id="password"
              name="password"
              autocomplete="current-password"
              spellcheck="false"
              autocorrect="off"
              autocapitalize="none"
              minlength="8"
              required
              @input="error = null"
            />
          </div>
          <div v-if="error" class="w-form-fail">
            <strong>Anmeldung fehlgeschlagen</strong><br />
            <span>{{ error }}</span>
          </div>
          <button
            :class="{ loading }"
            :disabled="loading"
            class="w-button"
            style="
              width: 100%;
              margin-top: 10px;
              font-weight: 600;
              height: 50px;
            "
          >
            Anmelden
          </button>
        </form>
      </div>
    </div>
    <div
      style="
        width: 100%;
        text-align: center;
        font-size: 14px;
        height: 44px;
        line-height: 44px;
        margin-top: -10px;
      "
    >
      <router-link :to="{ name: 'recover-password' }"
        >Passwort vergessen?</router-link
      >
    </div>

    <hr />

    <div
      class="block-article"
      style="
        margin-top: 1em;
        padding-top: 20px;
        background: transparent;
        border: none;
        box-shadow: none;
      "
    >
      <div style="width: 100%; text-align: center">
        <span style="text-align: center"
          >Noch kein stolzer Besitzer eines Profils?</span
        >
        <router-link
          :to="{ name: 'register' }"
          class="block-article-content participate-button w-button"
          >Jetzt kostenlos registrieren</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { firebase } from "@firebase/app";
import "@firebase/auth";

export default {
  name: "login",
  metaInfo: {
    title: "Anmelden",
  },
  data() {
    return {
      loading: false,
      error: null,
      credentials: {
        email: null,
        password: null,
      },
    };
  },
  computed: {
    user() {
      return firebase.auth().currentUser;
    },
  },
  mounted() {
    if (this.user) {
      this.$router.replace({ name: "profile" });
    }

    // Prefill email address and focus password field
    if (this.$route.query.email) {
      this.credentials.email = this.$route.query.email;
      document.getElementById("password").focus();
    }
  },
  methods: {
    login() {
      this.loading = true;
      const self = this;

      this.$store
        .dispatch("user/loginWithEmail", this.credentials)
        .then(() => {
          firebase.analytics().logEvent("login", { method: "email" });
          if (self.$route.query && self.$route.query.redirect) {
            self.$router.replace({ path: self.$route.query.redirect });
          } else {
            // self.$router.replace({ name: "profile" });
          }
        })
        .catch(err => {
          if (err.code === "auth/wrong-password") {
            this.error =
              "Die Kombination aus E-Mail-Adresse und Passwort ist falsch. Bitte prüfe deine Angaben erneut oder nutze die „Passwort vergessen”-Funktion!";
          } else if (err.code === "auth/user-not-found") {
            this.error =
              "Für diese E-Mail-Adresse ist noch kein Profil registriert. Bitte prüfe deine Angaben erneut oder registriere dich kostenlos!";
          } else {
            this.error = err.message;
          }
          this.loading = false;
        });
    },
  },
};
</script>
