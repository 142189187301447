<template>
  <div class="div-block-7 article-content-subpage" style="max-width: 500px">
    <div class="div-block-14" style="padding-bottom: 10px">
      <h1 class="heading-link heading-subpage">Konto erstellen</h1>
      <p>
        Mit deinem kostenlosen Konto kannst du deine Teilnahme an
        Veranstaltungen bestätigen und dich über wichtige Neuigkeiten zu
        Veranstaltungen (z.B. bei Absagen und Terminverschiebungen) informieren
        zu lassen.
      </p>
    </div>
    <div class="block-article">
      <div class="block-article-content">
        <form :class="{ loading }" class="form" v-on:submit.prevent="login">
          <div class="required">
            <label class="text-meta">Deine E-Mail-Adresse</label>
            <input
              class="w-input"
              v-model="credentials.email"
              placeholder="max.muster@email.de"
              type="email"
              name="email"
              autocomplete="email"
              spellcheck="false"
              autocorrect="off"
              autocapitalize="none"
              minlength="5"
              required
              @input="error = null"
            />
          </div>
          <div>
            <label class="text-meta">Dein Passwort (mind. 8 Zeichen)</label>
            <input
              class="w-input"
              v-model="credentials.password"
              placeholder="********"
              type="password"
              id="password"
              name="password"
              autocomplete="current-password"
              spellcheck="false"
              autocorrect="off"
              autocapitalize="none"
              minlength="8"
              required
              @input="error = null"
            />
          </div>
          <div
            class="w-checkbox"
            style="padding-top: 10px; padding-bottom: 10px"
          >
            <input
              type="checkbox"
              id="checkbox"
              name="checkbox"
              data-name="Checkbox"
              class="checkbox w-checkbox-input"
              required
            /><label
              for="checkbox"
              class="checkbox-label w-form-label"
              style="font-size: 14px; font-weight: 500; line-height: 20px"
              >Mit meiner Registrierung bestätige ich, dass ich die
              <router-link :to="{ name: 'data-policy' }"
                >Datenschutzbestimmungen</router-link
              >
              und
              <router-link :to="{ name: 'terms' }"
                >Nutzungsbedingungen</router-link
              >
              gelesen habe und akzeptiere diese.</label
            >
          </div>
          <div v-if="error" class="w-form-fail">
            <strong>Registrierung fehlgeschlagen</strong><br />
            <p>{{ error }}</p>
          </div>
          <button
            :class="{ loading }"
            :disabled="loading"
            class="w-button"
            style="
              width: 100%;
              margin-top: 10px;
              font-weight: 600;
              height: 50px;
            "
          >
            Registrieren
          </button>
        </form>
      </div>
    </div>
    <div
      style="
        width: 100%;
        text-align: center;
        font-size: 14px;
        height: 44px;
        line-height: 44px;
        margin-top: -10px;
      "
    >
      Du hast bereits ein Profil?
      <router-link :to="{ name: 'login' }">Zur Anmeldung</router-link>
    </div>
  </div>
</template>

<script>
import { firebase } from "@firebase/app";
import "@firebase/auth";

export default {
  name: "register",
  metaInfo: {
    title: "Konto erstellen",
  },
  data() {
    return {
      loading: false,
      error: null,
      credentials: {
        email: null,
        password: null,
      },
    };
  },
  computed: {
    user() {
      return firebase.auth().currentUser;
    },
  },
  mounted() {
    if (this.user) {
      this.$router.replace({ name: "profile" });
    }
  },
  methods: {
    login() {
      this.loading = true;
      const self = this;

      this.$store
        .dispatch("user/registerWithEmail", this.credentials)
        .then(() => {
          firebase.analytics().logEvent("sign_up", { method: "email" });
          self.$router.push({ name: "profile" });
        })
        .catch(err => {
          if (err.code === "auth/email-already-in-use") {
            this.error =
              "Für diese E-Mail-Adresse existiert bereits ein Profil. Falls du dich anmelden möchtest, nutze den Link unten.";
          } else {
            this.error = err.message;
          }
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
